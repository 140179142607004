<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template v-if="unreadMessages.length > 0">
      <v-card
        class="mx-auto"
        max-width="500"
      >
        <v-toolbar
          color="#FFB001"
          dark
        >
          <v-toolbar-title>{{ unreadMessages.length }} Unread Messages</v-toolbar-title>

          <v-spacer />
        </v-toolbar>

        <v-list two-line>
          <v-list-item-group
            active-class="orange--text"
            multiple
          >
            <template v-for="(item, index) in unreadMessages">
              <v-list-item :key="item.key">
                <template v-slot:default="{ }">
                  <v-list-item-content>
                    <router-link
                      style="text-decoration: none; color: inherit;"
                      :to="{ name: 'Customer', params: { campaign_id: item.campaign.uuid, customer_id: item.customer.uuid}, query: {show_conversation: 1} }"
                    >
                      <v-list-item-title v-text="item.customer.first_name + ' ' + item.customer.last_name" />
                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.body"
                      />
                      <v-list-item-subtitle v-text="formatIsoDate(item.created_at, true)" />
                    </router-link>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text v-text="item.action" />
                  </v-list-item-action>
                </template>
              </v-list-item>
              <v-divider
                v-if="index < unreadMessages.length - 1"
                :key="index"
              />
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </template>

    <v-row>
      <v-col
        sm="12"
        md="12"
        lg="6"
        xl="6"
      >
        <router-link
          style="text-decoration: none; color: inherit;"
          :to="{ name: 'Waiting On Us' }"
        >
          <base-material-card
            title=""
            color="#37474F"
            style="background-color: #FAFAFA"
          >
            <template v-slot:heading>
              <div
                class="display-2 font-weight-medium text-center"
                style="font-size: 22px !important"
              >
                <v-icon
                  large
                  color="primary"
                >
                  mdi-account-alert
                </v-icon>
                <span class="white--text">
                  WAITING ON US
                </span>
                <v-btn
                  absolute
                  top
                  right
                  fab
                  :color="getWaitingColor(waitingOnUsList.length)"
                >
                  <v-icon> {{ getWaitingIcon(waitingOnUsList.length) }}</v-icon>
                </v-btn>
              </div>
            </template>
            <div class="mt-0">
              <v-alert
                border="top"
                color="green lighten-2"
                dark
              >
                <strong>These are customers that have responded to us, and are waiting for our reply.</strong>
              </v-alert>
            </div>                 
            <div class="text-center display-4 font-weight-black mb-3 mt-3">
              {{ waitingOnUsList.length }}
            </div>
          </base-material-card>
        </router-link>
      </v-col>
      <v-col
        sm="12"
        md="12"
        lg="6"
        xl="6"
      >
        <router-link
          style="text-decoration: none; color: inherit;"
          :to="{ name: 'Waiting On Them' }"
        >
          <base-material-card
            title=""
            color="#37474F"
            style="background-color: #FAFAFA"
          >
            <template v-slot:heading>
              <div
                class="display-2 font-weight-medium text-center"
                style="font-size: 22px !important"
              >
                <v-icon
                  large
                  color="primary"
                >
                  mdi-account-clock
                </v-icon>
                <span class="white--text">
                  WAITING ON THEM
                </span>
                <v-btn
                  absolute
                  top
                  right
                  fab
                  :color="getWaitingColor(waitingOnThemList.length)"
                >
                  <v-icon> {{ getWaitingIcon(waitingOnThemList.length) }}</v-icon>
                </v-btn>
              </div>
            </template>
            <div class="mt-0">
              <v-alert
                border="top"
                color="green lighten-2"
                dark
              >
                <strong>These are customers that we made contact with, responded to, and are now waiting for them to respond to us.</strong>
              </v-alert>
            </div>              
            <div class="text-center display-4 font-weight-black mb-3 mt-3">
              {{ waitingOnThemList.length }}
            </div>
          </base-material-card>
        </router-link>
      </v-col>
    </v-row>
    <base-material-card
      icon="mdi-timetable"
      title="7 Day Activity History"
      color="secondary"
      style="background-color: #FAFAFA"
    >
      <v-row dense>
        <v-col
          sm="12"
          md="12"
          lg="4"
          xl="4"
        >
          <template v-if="customersImported.data.labels.length === 0">
            <div class="mt-0">
              <v-alert
                border="top"
                color="green lighten-2"
                dark
              >
                <strong><p class="font-weight-medium">We haven't imported any customers from your DMS yet, but once we do, this will show a graph of how many have recently been imported.</p></strong>
              </v-alert>
            </div>       
          </template>   
          <template v-else>         
            <base-material-chart-card
              :data="customersImported.data"
              :options="customersImported.options"
              color="light-green darken-1"
              type="Bar"
              class="px-4 py-3"
            >
              <h4 class="display-1 font-weight-light mt-2 text-center">
                <strong>Customers Imported from DMS</strong>
              </h4>
            </base-material-chart-card>
          </template>
        </v-col>
        <v-col
          sm="12"
          md="12"
          lg="4"
          xl="4"
        >
          <template v-if="textsSent.data.labels.length === 0">
            <div class="mt-0">
              <v-alert
                border="top"
                color="green lighten-2"
                dark
              >
                <strong><p class="font-weight-medium">You haven't sent any texts yet, but once you do, this will show a graph of how many recent texts were sent.</p></strong>
              </v-alert>
            </div>       
          </template> 
          <template v-else>     
            <base-material-chart-card
              :data="textsSent.data"
              :options="textsSent.options"
              color="light-green darken-1"
              type="Bar"
              class="px-4 py-3"
            >
              <h4 class="display-1 font-weight-light mt-2 text-center">
                <strong>Messages Sent</strong>
              </h4>
            </base-material-chart-card>
          </template>          
        </v-col>
        <v-col>
          <template v-if="textsReceived.data.labels.length === 0">
            <div class="mt-0">
              <v-alert
                border="top"
                color="green lighten-2"
                dark
              >
                <strong><p class="font-weight-medium">You haven't received any texts yet, but once you do, this will show a graph of how many recent texts were received.</p></strong>
              </v-alert>
            </div>       
          </template>
          <template v-else>                   
            <base-material-chart-card
              :data="textsReceived.data"
              :options="textsReceived.options"
              color="light-green darken-1"
              type="Bar"
              class="px-4 py-3"
            >
              <h4 class="display-1 font-weight-light mt-2 text-center">
                <strong>Messages Received</strong>
              </h4>
            </base-material-chart-card>
          </template>
        </v-col>
      </v-row>
    </base-material-card>

    <base-material-card
      icon="mdi-rocket"
      title="Missions"
      color="secondary"
      style="background-color: #FAFAFA"
    >
      <v-row dense>
        <v-col>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="items"
              :search="search"
              :items-per-page="50"
              :sort-by="['name']"
            >
              <template #item.name="{item}">
                {{ item.name }}
                <template v-if="item.paused_at">
                  <v-chip
                    class="ma-2"
                    color="primary"
                  >
                    Paused
                  </v-chip>
                </template>
              </template>
              <template #item.last_dms_sync_ended_at="{value}">
                {{ formatIsoDate(value, true) }}
              </template>
              <template #item.number_unread_sms="{value}">
                {{ formatNumber(value) }}
              </template>
              <template #item.number_sent_sms="{value}">
                {{ formatNumber(value) }}
              </template>
              <template #item.number_appointments_set="{value}">
                {{ formatNumber(value) }}
              </template>    
              
              <template v-slot:item.number_appointments_set="{ item }">
                <router-link
                  style="cursor: pointer; color: blue"
                  :to="{ name: 'Recent Appointments Set', params: { campaign_id: item.uuid } }"
                >
                  {{ formatNumber(item.number_appointments_set) }}
                </router-link>
              </template>


              <template #item.opt_outs="{value, item}">
                <div
                  style="cursor: pointer; color: blue; text-decoration: underline"
                  @click="showOptOuts(item.opt_outs)"
                >
                  {{ formatNumber(item.opt_outs.length) }}
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <template v-if="!item.paused_at">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        class="mr-2"
                        v-on="on"
                        @click="pauseMission(item)"
                      >
                        mdi-pause
                      </v-icon>
                    </template>
                    <span>Pause Mission</span>
                  </v-tooltip>
                </template>
                <template v-if="item.paused_at">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        class="mr-2"
                        v-on="on"
                        @click="resumeMission(item)"
                      >
                        mdi-play
                      </v-icon>
                    </template>
                    <span>Resume Mission</span>
                  </v-tooltip>
                </template>                
                <router-link
                  style="text-decoration: none; color: inherit;"
                  :to="{ name: 'Edit Mission', params: { campaign_id: item.uuid } }"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        small
                        class="mr-2"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                  </template>
                  <span>Edit Mission</span>
                </v-tooltip>                           
                </router-link>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">                
                    <v-icon
                      small
                      @click="deleteItem(item)"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete Mission</span>
                </v-tooltip>                        
              </template>
              <template v-slot:item.chatovate_customers_count="{ item }">
                <router-link
                  style="cursor: pointer; color: blue"
                  :to="{ name: 'Customers', params: { campaign_id: item.uuid } }"
                >
                  {{ formatNumber(item.chatovate_customers_count) }}
                </router-link>
              </template>
              <template v-slot:item.number_sales="{ item }">
                <router-link
                  style="cursor: pointer; color: blue"
                  :to="{ name: 'Matchbacks', params: { campaign_id: item.uuid }, query: { customer_type: 'Sales' } }"
                >
                  {{ formatNumber(item.number_sales) }}
                </router-link>
              </template>
              <template v-slot:item.number_ros="{ item }">
                <router-link
                  style="cursor: pointer; color: blue"
                  :to="{ name: 'Matchbacks', params: { campaign_id: item.uuid }, query: { customer_type: 'Service' } }"
                >
                  {{ formatNumber(item.number_ros) }}
                </router-link>
              </template>                         
            </v-data-table>
            <v-row dense>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  @click="addNewCampaign()"
                >
                  Add New Mission
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </base-material-card>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="showOptOutDialog"
      width="700px"
      scrollable
    >
      <v-card
        class="mx-auto"
      >
        <v-toolbar
          color="#37474F"
          dark
        >
          <v-toolbar-title>Opt Outs</v-toolbar-title>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="optOutHeaders"
          :items="optOuts"
          :search="search"
          :items-per-page="100"
          :sort-by="['phone']"
        >
          <template #item.phone="{value}">
            {{ formatPhoneNumber(value) }}
          </template>
          <template v-slot:item.name="{ item }">
            <router-link
              style="cursor: pointer; color: blue; text-decoration: underline"
              :to="{ name: 'Customer', params: { campaign_id: item.chatovate_campaign_uuid, customer_id: item.chatovate_customer_uuid } }"
            >
              {{ item.first_name }} {{ item.last_name }}
            </router-link>
          </template>          
        </v-data-table>
        <v-card-actions>
          <v-btn
            @click="showOptOutDialog = false"
            color="primary"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noPermissions"
      persistent
      width="600"
    >
      <v-card
        color="warning"
        dark
      >
        <v-alert
          type="warning"
        >
          You do not have permissions to access this feature. Contact Dealer Insights support for further assistance.
        </v-alert>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="goHome()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ChatovateService from '@/services/ChatovateService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'
  import chatovateCommonMixin from '@/mixins/chatovateCommonMixin.js'

  export default {
    name: 'ChatovateCampaigns',
    components: {
      ErrorMessage,
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },
    },
    mixins: [formatterMixin, titleMixin, chatovateCommonMixin],
    data () {
      return {
        loadingMessage: 'Loading',
        showErrorDialog: false,
        items: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        unreadMessages: [],
        noPermissions: false,
        headers: [
          { text: 'Name', value: 'name' },
          { text: 'Mission Type', value: 'mission_type', align: 'center' },
          { text: '# Customers', value: 'chatovate_customers_count', align: 'center' },
          { text: '# Unread Messages', value: 'number_unread_sms', align: 'center' },
          { text: '# Messages Sent', value: 'number_sent_sms', align: 'center' },
          { text: '# Appointments Set', value: 'number_appointments_set', align: 'center' },
          { text: '# Sales', value: 'number_sales', align: 'center' },
          { text: '# ROs', value: 'number_ros', align: 'center' },
          { text: 'Last DMS Synch', value: 'last_dms_sync_ended_at', align: 'center' },
          { text: '# Opt Outs', value: 'opt_outs', align: 'center' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        optOutHeaders: [
          { text: 'Name', value: 'name' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Reason', value: 'reason', align: 'center' },
        ],
        showOptOutDialog: false,
        optOuts: [],
        accountStats: null,
        customersImported: {
          data: {
            labels: [],
            series: [
              [],
            ],
          },
          options: {
            low: 0,
            high: 0,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        textsSent: {
          data: {
            labels: [],
            series: [
              [],
            ],
          },
          options: {
            low: 0,
            high: 0,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        textsReceived: {
          data: {
            labels: [],
            series: [
              [],
            ],
          },
          options: {
            low: 0,
            high: 0,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        waitingOnUsList: [],
        waitingOnThemList: [],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      ...mapGetters(['accountId', 'accountName', 'chatovateSms', 'permissions', 'chatovateSentSms']),
    },
    watch: {
      accountId () {
        this.loadCampaigns()
      },
      chatovateSms () {
        this.items.filter(i => i.uuid === this.chatovateSms.campaign.uuid)[0].number_unread_sms++
        const newMessage = { key: Math.random(), body: this.chatovateSms.body, source: 'customer', created_at: new Date().toISOString(), customer: this.chatovateSms.customer, campaign: this.chatovateSms.campaign }
        this.unreadMessages.push(newMessage)

        var existingCustomer = this.waitingOnUsList.filter(i => i.uuid === this.chatovateSms.customer.uuid)[0]
        if (existingCustomer) {
        } else {
          // This customer isn't already in this list, so we need to add it.
          const newCustomer = { name: this.chatovateSms.customer.first_name + ' ' + this.chatovateSms.customer.last_name, lmr: this.chatovateSms.body, lmr_at: new Date().toISOString(), lms: this.chatovateSms.customer.lms, lms_at: this.chatovateSms.customer.lms_at, uuid: this.chatovateSms.customer.uuid }
          this.waitingOnUsList.push(newCustomer)
        }
        // Is this customer in the WaitingOnThem list? If so, we need to remove them.
        existingCustomer = this.waitingOnThemList.filter(i => i.uuid === this.chatovateSms.customer.uuid)[0]
        if (existingCustomer) {
          this.waitingOnThemList = this.waitingOnThemList.filter(i => i.uuid !== existingCustomer.uuid)
        }
      },
      chatovateSentSms () {
        if (this.chatovateSentSms.source === 'dealer') {
          var existingCustomer = this.waitingOnThemList.filter(i => i.uuid === this.chatovateSentSms.customer.uuid)[0]
          if (existingCustomer) {
          } else {
            // This customer isn't already in this list, so we need to add it.
            const newCustomer = { name: this.chatovateSentSms.customer.first_name + ' ' + this.chatovateSentSms.customer.last_name, lms: this.chatovateSentSms.message, lms_at: new Date().toISOString(), lmr: this.chatovateSentSms.customer.lmr, lmr_at: this.chatovateSentSms.customer.lmr_at, uuid: this.chatovateSentSms.customer.uuid, chatovate_campaign_idå: this.chatovateSentSms.customer.chatovate_campaign_id }
            this.waitingOnThemList.push(newCustomer)
          }
          // Is this customer in the WaitingOnUs list? If so, we need to remove them.
          existingCustomer = this.waitingOnUsList.filter(i => i.uuid === this.chatovateSentSms.customer.uuid)[0]
          if (existingCustomer) {
            this.waitingOnUsList = this.waitingOnUsList.filter(i => i.uuid !== existingCustomer.uuid)
          }
        }
      },
    },
    created () {
      if (!this.permissions.includes('chatovate')) {
        this.noPermissions = true
      }
      this.loadCampaigns()
      this.getWaitingOnUs()
      this.getWaitingOnThem()
      this.getAccountStats()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadCampaigns: function () {
        this.loadingMessage = 'Loading Missions'
        this.items = []
        this.loading = true
        ChatovateService.getAllChatovateCampaigns()
          .then(response => {
            this.items = response.data
            this.unreadMessages = this.items.filter(item => item.number_unread_sms > 0).map(item => (item.unread_sms.map(u => ({ key: Math.random(), body: u.body, source: 'customer', created_at: u.created_at, campaign: item, customer: u.customer })))).flat()
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getAccountStats: function () {
        this.loadingMessage = 'Gathering Stats'
        this.loading = true
        ChatovateService.getAccountStats()
          .then(response => {
            this.accountStats = response.data
            this.customersImported.data = this.accountStats.customers_imported
            this.customersImported.options.high = Math.max(...this.accountStats.customers_imported.series[0])
            this.textsSent.data = this.accountStats.texts_sent
            this.textsSent.options.high = Math.max(...this.accountStats.texts_sent.series[0])
            this.textsReceived.data = this.accountStats.texts_received
            this.textsReceived.options.high = Math.max(...this.accountStats.texts_received.series[0])
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getWaitingOnUs: function () {
        this.loadingMessage = 'Getting Waiting On Us'
        this.loading = true
        ChatovateService.getWaitingOnUs()
          .then(response => {
            this.waitingOnUsList = response.data
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getWaitingOnThem: function () {
        this.loadingMessage = 'Getting Waiting On Them'
        this.loading = true
        ChatovateService.getWaitingOnThem()
          .then(response => {
            this.waitingOnThemList = response.data
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      deleteItem (item) {
        const index = this.items.indexOf(item)
        if (confirm('Are you sure you want to delete this Mission?') && this.items.splice(index, 1)) {
          // Call the API to update.
          this.loadingMessage = 'Deleting Mission'
          this.loading = true
          ChatovateService.deleteChatovateCampaign(item.uuid)
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      pauseMission (item) {
        const index = this.items.indexOf(item)
        this.loadingMessage = 'Pausing Mission'
        this.loading = true
        ChatovateService.pauseMission(item.uuid)
          .then(response => {
            this.loading = false
            this.loadCampaigns()
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      resumeMission (item) {
        const index = this.items.indexOf(item)
        this.loadingMessage = 'Resuming Mission'
        this.loading = true
        ChatovateService.resumeMission(item.uuid)
          .then(response => {
            this.loading = false
            this.loadCampaigns()
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      addNewCampaign: function () {
        this.$router.push({ name: 'New Mission', query: { step: 1 } })
      },
      showOptOuts: function (item) {
        this.optOuts = item
        this.showOptOutDialog = true
      },
      goHome: function () {
        this.$router.push({ name: 'Home' })
      },
    },
  }
</script>
